import React from 'react'
import Image from 'next/future/image'

const StorySection = () => {
  const features = [
    {
      title: "Mood Board Editor",
      description: "Our journey began with the Mood Board Editor, a tool designed to make mood boards beautiful, functional, and practical with features like integrated shopping lists."
    },
    {   
      title: "The AI Revolution",
      description: "As AI technology advanced, we recognized an opportunity to enhance our tools. The result? MyRoomDesigner.AI and MoodBoardAI—a full suite for every designer's needs."
    },
    {
      title: "Future Vision",
      description: "We're constantly evolving, driven by feedback from designers like you. Our mission is to build a tool that supports every aspect of your creative journey, now and in the future."
    }
  ]

  const founders = [
    {
      name: "Marta Herget",
      role: "Founder & CEO",
      image: "https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/Screenshot-2024-10-09-at-09-17-00.png"
    },
    {
      name: "Gabor Herget",
      role: "Founder & Developer",
      image: "/assets/Gabor.jpg"
    }
  ]

  return (
    <section id="about-us" className="py-36 overflow-hidden w-full">
      <div className="container mx-auto px-6">
        {/* Title and Intro Section */}
        <div className="text-center mb-16">
          <p className="mb-5 font-heading font-medium text-xs tracking-wider text-transparent bg-clip-text bg-gradient-cyan uppercase">
            Our Story
          </p>
          <h2 className="font-heading text-5xl sm:text-7xl md:text-9xl xl:text-10xl">
            Where it all began
          </h2>
          <p className="mt-6 text-lg md:text-xl leading-relaxed max-w-full md:max-w-3xl mx-auto font-heading text-gray-600">
            Back in 2020, with a simple idea in mind, we set out to create a tool for interior designers. 
            Gabor saw Marta struggling with limited mood board tools, which were often either beautiful 
            but impractical, or functional but uninspiring.
          </p>
          <p className="mt-4 text-lg md:text-xl leading-relaxed max-w-full md:max-w-3xl mx-auto font-heading text-gray-600">
            This inspired us to build our own tool that merged aesthetics and functionality—the MoodBoardly 
            Mood Board Editor. As AI technology advanced, so did our vision, and we expanded to create 
            MyRoomDesigner.AI and MoodBoardAI, a full toolkit for every creative journey.
          </p>
        </div>

        {/* Features & Milestones Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-12 mb-20">
          {features.map((feature, index) => (
            <div key={index} className="p-6 shadow-sm rounded-lg bg-[#f7f6f4]">
              <h3 className="font-heading text-2xl font-semibold text-gray-800">
                {feature.title}
              </h3>
              <p className="mt-4 text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        {/* Meet the Founders Section */}
        <div className="text-center">
          <h2 className="font-heading text-3xl md:text-5xl text-gray-900 mb-16">
            Meet the Founders
          </h2>
          <div className="flex flex-wrap justify-center gap-10 md:gap-20">
            {founders.map((founder, index) => (
              <div key={index} className="text-center w-full md:w-auto">
                <Image
                  className="w-36 h-36 mx-auto rounded-full mb-4"
                  src={founder.image}
                  alt={founder.name}
                  width={144}
                  height={144}
                />
                <h3 className="font-heading font-semibold text-xl text-gray-800">
                  {founder.name}
                </h3>
                <p className="text-xs text-gray-600 mb-4">
                  {founder.role}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default StorySection 