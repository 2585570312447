import React, {useState, useEffect, useContext} from 'react'

import ProfileThumb from './ProfileThumb'
import { useTranslation } from 'next-i18next'
import BoardImage from "./BoardImage"
import Link from "next/link"

const BoardListItem = ({ id, image, status, user, showUser, title, isImageDirty, openEdit = false }) => {
    const { t } = useTranslation()
    const link = id ? `${openEdit ? '/edit' : ''}/${id}` : "#"
    
    return (
        <div className="flex flex-col">
                {showUser && 
                    <div className="h-9 m-2">
                        <ProfileThumb uid={user} showName={true} />
                    </div>
                }
                   <Link href={link} passHref prefetch={false}>
                    <a className={`border border-gray-100 bg-gradient-to-b from-white to-gray-50 block relative overflow-hidden cursor-pointer rounded-xl ${!id ? 'animate-pulse' : ''}`} >
                    <div className="aspect-[16/13]">
                        <div>
                            <div className="w-full h-full"><BoardImage id={id} src={image} className='mix-blend-darken' width="620" height="500" alt={title || "Mood Board"} boardId={id} isImageDirty={isImageDirty}/></div>
                            {id && status !== "public" && 
                                <div className="absolute top-0 right-0 uppercase p-2 bg-gray-200 text-xs text-gray-700">{t('board.draft')}</div>
                            }
                        </div>
                    </div>
                    </a>
                    </Link>
        </div>)}
export default BoardListItem
