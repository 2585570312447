import React, {useState, useEffect, useContext} from 'react'

import { listBoards } from './Firebase'
import BoardListItem from './BoardListItem'
import { useTranslation } from 'next-i18next'
import LoadingButton from './LoadingButton'

const Boards = (props) => {
  const { title, boards: boardsProp, showLoadMore: showLoadMoreProp = true } = props
  const [boards, setBoards] = useState(boardsProp)
  const [showLoadMore, setShowLoadMore] = useState(showLoadMoreProp && boardsProp.length === props.limit)
  const { t } = useTranslation()

  useEffect(() => {
    setBoards(boardsProp)
    setShowLoadMore(showLoadMoreProp && +boardsProp.length === +props.limit)
  }, [boardsProp])

  const loadMore = async () => {
    const lastBoard = boards[boards.length-1]
    if(lastBoard?.created?.seconds) {
      lastBoard.created = new Date(lastBoard.created.seconds * 1000)
    }
    const newBoards = await listBoards({ ...props, startAfter: lastBoard })
    setBoards(boards => [...boards, ...newBoards])
    if (!newBoards.length || newBoards.length < props.limit) setShowLoadMore(false)
  }

    return (
      <div className="my-10 2xl:mx-10">
        {title && boards.length > 0 && (
          <h2 className="py-3 text-3xl text-center text-gray-700">{title}</h2>
        )}
        <section className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8 sm:gap-12 2xl:gap-14 text-gray-600 p-6 sm:p-8 ">
          {boards.map((board, i) => (
            <BoardListItem key={i} {...props} {...board} />
          ))}
        </section>
        {showLoadMore && <div className="text-center">
          <LoadingButton onClick={loadMore} highlight>
            {t("load-more")}
          </LoadingButton>
        </div>}
      </div>
    )
}

const BoardList = (props) => {
    const { sortBy, limit = 12 } = props
    const [boards, setBoards] = useState([...new Array(+limit)])

    const loadBoards = async () => {
        const result = await listBoards({...props})
        console.log('load boards',result)
        if(result instanceof Error) {
            console.error(result)
            return
        }  
        setBoards(result)
    }
    useEffect(() => {
        loadBoards()
    }, [sortBy, props.filterUser])

    return <Boards {...props} boards={boards}/>
}
 
export default BoardList;
export { Boards }