import React, { useState, useEffect, useContext } from 'react';
import { loadUserPublicDetailsFromUid } from './FirebaseApp'

import Link from "next/link"

const ProfileThumb = ({ uid, userPublic, showName = false, linkToAccountAdmin = false}) => {
    const [profile, setProfile] = useState(userPublic)

    const loadProfileUrlFromUid = async uid => {
        setProfile(await loadUserPublicDetailsFromUid(uid))
    } 

    useEffect(() => {
        if(uid) loadProfileUrlFromUid(uid)
    }, [uid]);

    if(!profile || !profile.photoURL) return ""

    const { username, photoURL, displayName } = profile

    return ( 
        <>
        <Link href={linkToAccountAdmin ? '/dashboard' : `/p/${username}`} passHref prefetch={false}>
            <a className="h-full flex items-center space-x-2">
            <img src={photoURL} className="h-12 w-12 object-cover rounded-full hover:ring hover:ring-pink-200" alt={displayName} />
            {showName && <span>{displayName.split(' ')[0]}</span>}
            </a>
        </Link>
        </>
    );
}
 
export default ProfileThumb;

