import React, { useEffect, useContext, lazy, Suspense } from "react"
import Image from "next/image"

import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import { Chair } from "@styled-icons/material-outlined/Chair"
import Link from "next/link"

const HeroWidget = props => {
  const router = useRouter()
  const { t } = useTranslation()

  const onCreate = () => {
    router.push(`/new-board`)
  }

  return (
    <>
      <section className="overflow-hidden animated-gradient-bg bg-gradient-to-r from-red-100 via-blue-50 to-gray-50">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center -m-6 pt-16 pb-20">
            {/* Reduced padding-top and padding-bottom */}
            <div className="w-full lg:w-1/2 p-6">
              <div className="lg:max-w-xl">
                <div className="overflow-x-auto pb-4 mb-8">
                  <div className="flex space-x-4 w-max">
                    <Link href="/ai-generator">
                      <p className="font-heading max-w-max px-4 py-2.5 font-black text-xs tracking-wider text-pink-800 bg-gradient-to-r from-pink-100 to-gray-50 rounded-full cursor-pointer">
                        Mood Board AI
                      </p>
                    </Link>
                    <Link href="/#moodboard-editor">
                      <p className="font-heading max-w-max px-4 py-2.5 font-extralight text-xs tracking-wider text-gray-400 bg-gradient-to-r from-gray-100 to-gray-50 rounded-full cursor-pointer">
                        Board Editor
                      </p>
                    </Link>
                    <Link href="/interior-design-ai">
                      <p className="font-heading max-w-max px-4 py-2.5 font-extralight text-xs tracking-wider text-gray-400 bg-gradient-to-r from-blue-100 to-gray-50 rounded-full cursor-pointer">
                        MyRoomDesigner.AI
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="flex items-center mb-8">
                  <div className="flex -m-0.5 mr-3">
                    <div className="p-0">
                      <svg
                        width={17}
                        height={16}
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
                          fill="#be185d"
                        />
                      </svg>
                    </div>
                    <div className="p-0">
                      <svg
                        width={17}
                        height={16}
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
                          fill="#be185d"
                        />
                      </svg>
                    </div>
                    <div className="p-0">
                      <svg
                        width={17}
                        height={16}
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
                          fill="#be185d"
                        />
                      </svg>
                    </div>
                    <div className="p-0">
                      <svg
                        width={17}
                        height={16}
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
                          fill="#be185d"
                        />
                      </svg>
                    </div>
                    <div className="p-0">
                      <svg
                        width={17}
                        height={16}
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
                          fill="#be185d"
                        />
                      </svg>
                    </div>
                  </div>
                  <h1 className="text-gray-900 text-xs font-bold">
                    The Nr.1 AI Mood Board Designer
                  </h1>
                </div>
                <div className="flex items-center mb-0">
                  <div className="flex -m-0.5">
                    <div className="p-0.5" />
                  </div>
                </div>
                <h1 className="mb-6 font-heading text-7xl md:text-10xl xl:text-12xl text-gray-900">
                  Create Stunning Mood Boards in Seconds
                </h1>
                <p className="mb-9 text-gray-900 text-lg">
                  Our AI-Powered Moodboard Maker simplifies the process—just
                  choose your style, and let our smart technology craft
                  beautiful, professional mood boards tailored to you. Say
                  goodbye to design stress and hello to creativity!
                </p>
                <div className="flex space-x-4">
                  <button className="group relative font-heading block py-2 px-5 text-base rounded-full cursor-pointer text-white font-heading bg-gradient-to-r from-pink-900 to-pink-700 hover:ring-2 hover:ring-pink-300 }">
                    <a className="relative z-10 " href="/ai-generator">
                      Start to create now
                    </a>
                  </button>
                  {/* New minimalistic button */}
                  <button className="flex items-center space-x-2 text-gray-900 font-heading text-xs transition hover:text-gray-700">
                    <a
                      href="https://docs.moodboardly.com/learn-about-the-ai-powered-mood-board-creator"
                      target="_blank"
                    >
                      Learn how it works
                    </a>
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                </div>
                {/* Features List */}
                <ul className="flex flex-col items-start mt-8 text-black">
                  <li className="flex items-center mb-2">
                    {/* Add mb-4 for spacing */}
                    <div className="bg-green-100 rounded-full p-1 flex justify-center items-center">
                      <svg
                        className="w-5 h-5 text-black"
                        fill="none"
                        viewBox="0 0 19 19"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.95831 10.2917L7.12498 13.4584L15.0416 5.54169"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <a
                      className="font-heading text-black text-base ml-2"
                      href="/ai"
                    >
                      Free moodboard gallery
                    </a>
                  </li>
                  <li className="flex items-center">
                    <div className="bg-green-100 rounded-full p-1 flex justify-center items-center">
                      <svg
                        className="w-5 h-5 text-black"
                        fill="none"
                        viewBox="0 0 19 19"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.95831 10.2917L7.12498 13.4584L15.0416 5.54169"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="font-heading text-black text-base ml-2">
                      Cancel anytime
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-6">
              <Image
                className="block mx-auto"
                src="https://static.shuffle.dev/uploads/files/d9/d9b64af9678a8b251315f02b299124c86fe0fc01/Mood-Board-Generation-With-AI.png"
                alt=""
                layout="responsive"
                width={100}
                height={100}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="relative pt-48 overflow-hidden">
        <div className="container mx-auto px-4 mb-12">
          <h3 className="mb-2 max-w-2xl mx-auto font-heading text-center text-2xl text-transparent bg-clip-text bg-gradient-cyan font-heading tracking-px">
            Say goodbye to design stress
          </h3>
          <h2 className="max-w-2xl mx-auto font-heading text-center text-6xl sm:text-7xl text-transparent bg-clip-text bg-gradient-cyan font-heading tracking-px">
            <span className="font-bold">And hello to creativity</span>
          </h2>
        </div>
      </section>

      <section className="pt-36 pb-48">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap items-center -mx-4 mb-16">
            <div className="w-full md:w-1/2 px-4 mb-16 md:mb-0">
              <span className="inline-block py-px px-2 mb-4 text-xs leading-5 uppercase rounded-full shadow-sm bg-pink-100">
                How it works
              </span>
              <h2 className="mb-6 text-4xl leading-tight tracking-tighter font-heading sm:text-7xl">
                Mood Board AI helps you create with one click
              </h2>
              <p className="text-lg text-coolGray-500">
                Whether for interior design, fashion, or restaurant branding,
                Mood Board AI turns ideas into stunning mood boards
                effortlessly.
              </p>
              <a
                href="/ai-generator"
                className="inline-block font-heading py-2 px-5 text-base rounded-full cursor-pointer text-white bg-gradient-to-r from-pink-900 to-pink-700 hover:ring-2 hover:ring-pink-300 mt-4"
              >
                Start now
              </a>
            </div>
            <div className="w-full md:w-1/2 px-4">
              <Image
                src="https://static.shuffle.dev/uploads/files/29/29ed9324f34ff57161e62e45b1e365a6c2cf9f02/3.png"
                alt="How it works image"
                layout="responsive"
                width={871}
                height={697} // Assuming it's a square image
                className="rounded-7xl" // Added rounded corners here
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-4 text-center md:text-left">
            <div className="w-full md:w-1/2 md:w-1/3 px-4 mb-8">
              <div className="inline-flex items-center justify-center mb-4 w-12 h-12 text-xl font-semibold rounded-full bg-pink-100">
                1
              </div>
              <h3 className="mb-2 font-bold font-heading text-2xl">
                Start with Your Vision
              </h3>
              <p className="text-coolGray-500">
                Whether you're designing a room, curating fashion looks, or
                planning a wedding, begin by selecting your creative focus.
              </p>
            </div>
            <div className="w-full md:w-1/2 md:w-1/3 px-4 mb-8">
              <div className="inline-flex items-center justify-center mb-4 w-12 h-12 text-xl font-semibold rounded-full bg-pink-100">
                2
              </div>
              <h3 className="mb-2 font-bold font-heading text-2xl">
                Style Selection
              </h3>
              <p className="text-coolGray-500">
                Choose design aesthetics like contemporary, vintage, or
                minimalist to kickstart your creative journey.
              </p>
            </div>
            <div className="w-full md:w-1/2 md:w-1/3 px-4 mb-8">
              <div className="inline-flex items-center justify-center mb-4 w-12 h-12 text-xl font-semibold rounded-full bg-pink-100">
                3
              </div>
              <h3 className="mb-2 font-bold font-heading text-2xl">
                Additional Customization
              </h3>
              <p className="text-coolGray-500">
                Add your extra wishes and specific elements you desire, whether
                it's plants, curved forms, or a fairytale style.
              </p>
            </div>
            <div className="w-full md:w-1/2 md:w-1/3 px-4 mb-8 md:mb-0">
              <div className="inline-flex items-center justify-center mb-4 w-12 h-12 text-xl font-semibold rounded-full bg-pink-100">
                4
              </div>
              <h3 className="mb-2 font-bold font-heading text-2xl">
                Image Recommendations
              </h3>
              <p className="text-coolGray-500">
                Choose your favorite from the four suggested boards, upscale the
                selected image for an enhanced visual impact, or create similar
                ones to the chosen image.
              </p>
            </div>
            <div className="w-full md:w-1/2 md:w-1/3 px-4 mb-8 md:mb-0">
              <div className="inline-flex items-center justify-center mb-4 w-12 h-12 text-xl font-semibold rounded-full bg-pink-100">
                5
              </div>
              <h3 className="mb-2 font-bold font-heading text-2xl">
                Fine-Tune Your AI Design in Moments
              </h3>
              <p className="text-coolGray-500">
                Refine your AI-generated design effortlessly with MoodBoardly's
                intuitive "Clean Up and Replace" feature.
              </p>
            </div>
            <div className="w-full md:w-1/2 md:w-1/3 px-4">
              <div className="inline-flex items-center justify-center mb-4 w-12 h-12 text-xl font-semibold rounded-full bg-pink-100">
                6
              </div>
              <h3 className="mb-2 font-bold font-heading text-2xl">
                Product Suggestions
              </h3>
              <p className="text-coolGray-500">
                Receive recommendations for products or items that seamlessly
                align with your mood board's style and theme.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div id="moodboard-editor" className="w-full bg-gray-50 pb-12 pt-20 md:pb-20">
        <div className="container mx-auto max-w-screen-lg text-center">
          <div className="text-center mb-12 px-8">
            <h2 className="font-serif text-3xl md:text-4xl font-semibold">
              {t("hero.title")}
            </h2>
            <h3 className="mt-4 text-lg opacity-80 leading-relaxed text-gray-600">
              {t("hero.main-description")}
            </h3>
          </div>
          <div className="items-center">
            <div className="w-full px-8 pt-8 md:pt-0">
              <video
                className="rounded-lg shadow-xl"
                autoPlay
                loop
                muted
                playsInline
                poster="/assets/MoodBoardly-maroccan-style.jpg"
              >
                <source
                  src="/assets/MoodBoardly-maroccan-style.mp4"
                  type="video/mp4"
                />
              </video>
            </div>

            <div className="flex items-center justify-center gap-3 flex-wrap mt-10">
              <button
                onClick={onCreate}
                data-testid="add-board"
                className="lg:text-xl bg-pink-900 hover:bg-pink-700 py-3 px-6 text-white rounded-full focus:outline-none"
              >
                {t("hero.create-board")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <section className="relative py-20">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style={{ height: "80px" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto max-w-screen-lg text-center">
          <div className="text-center mb-12 px-8">
            <h2 className="font-serif text-3xl md:text-4xl font-semibold">
              {t("hero.add-own-product")}
            </h2>
            <h3 className="mt-4 text-lg opacity-80 leading-relaxed text-gray-600">
              {t("hero.add-own-description")}
            </h3>
          </div>
          <div className="items-center">
            <div className="w-full px-8 pt-8 md:pt-0">
              <video
                className="rounded-lg shadow-xl"
                autoPlay
                loop
                muted
                playsInline
              >
                <source
                  src="/assets/add-your-own-products.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </section>

      <section className="relative py-20 md:pb-40">
        <div className="container mx-auto px-4">
          <div className="items-center flex flex-wrap-reverse md:flex-wrap">
            <div className="w-full md:w-4/12 ml-auto mr-auto px-4 pt-8 md:pt-0">
              <video
                className="rounded-lg shadow-xl"
                autoPlay
                loop
                muted
                playsInline
              >
                <source src="/assets/remove-bg.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">
                <div className="text-pink-900 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-200">
                  <Chair className="w-7 h-7" />
                </div>
                <h3 className="font-serif text-3xl font-semibold">
                  {t("hero.remove-bg-title")}
                </h3>
                <p className="mt-4 text-lg leading-relaxed text-gray-600">
                  {t("hero.remove-bg-description")}
                </p>
                <ul className="list-none mt-6 mb-8">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"></span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          {t("hero.remove-bg-oneclick")}
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                          {/* <i className="fab fa-html5"></i> */}
                        </span>
                      </div>
                      <div>
                        <h4 className="text-gray-600">
                          {t("hero.remove-bg-clever")}
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <button
                onClick={onCreate}
                data-testid="add-board"
                className="bg-pink-900 hover:bg-pink-700 py-2 px-4 text-white rounded-full focus:outline-none"
              >
                Design now
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HeroWidget