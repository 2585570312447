import React, { useEffect, useContext, lazy, Suspense } from "react"

import { useTranslation } from "next-i18next"
import { Search } from "@styled-icons/boxicons-regular/Search"
import { Button } from "./LoadingButton.jsx"

const FeaturesWidget = props => {
  const { t } = useTranslation()

  return (
    <div>
      <section className="relative py-20 pb-36 bg-gray-50">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style={{ height: "80px" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-gray-50 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto px-4">
          <div className="flex flex-wrap-reverse md:flex-wrap md:flex-row-reverse">
            <div className="w-full md:w-4/12 ml-auto mr-auto px-4 pt-8 md:pt-0">
              <video className="rounded-lg shadow-xl" autoPlay loop muted playsInline>
                <source
                  src="/assets/furniture-database-and-import.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">
                <div className="text-pink-900 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-200">
                  <Search className="w-7 h-7" />
                </div>
                <h3 className="font-serif text-3xl font-semibold">
                  {t('features.db-title')}
                </h3>
                <p className="mt-4 mb-8 text-lg leading-relaxed text-gray-600">
                  {t('features.db-descr')}
                </p>
                <div className='grid grid-cols-3 gap-8 py-10 '>
                  <img src='/assets/partners/lulu-georgia.svg' className="h-full mx-auto object-contain" loading='lazy' />
                  <img src='/assets/partners/horne.webp' className="h-3 w-28 my-auto mx-auto object-contain" loading='lazy' />
                  <img src='/assets/partners/our-place.svg' className="h-4 w-28 my-auto mx-auto object-contain" loading='lazy' />
                </div>
                <Button href='/furniture' className='mt-8' highlight>🪑 {t('features.db-button')}</Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative pb-24">
      <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style={{ height: "80px" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        </section>
    </div>
  )
}

export default FeaturesWidget
