import React, { useState, useContext, useEffect } from "react"
import Image from "next/image"
import { loadBoardImageUrl } from "./FirebaseApp"
import { FirebaseContext } from "./Firebase"
import Loading from "../src/Loading"
import { useTranslation } from 'next-i18next'

function RenderedImage(props) {
  const { boardId, onImage } = props
  const fb = useContext(FirebaseContext)
  const [imageUrl, setImageUrl] = useState(null)

  async function rerenderImage(url) {
    await import("firebase/functions")
    const rerenderCanvasImage = fb.app
      .functions()
      .httpsCallable("rerenderCanvasImage")
    await rerenderCanvasImage({ boardId })
    const newImageUrl = await loadBoardImageUrl(boardId)
    setImageUrl(newImageUrl)
    if(onImage && typeof onImage === "function") onImage(newImageUrl)
  }

  useEffect(() => {
    if (boardId) {
      console.log("rerendering image")
      rerenderImage(boardId)
    }
  }, [boardId])

  if (!imageUrl) return <Loading />

  return <Image {...props} src={imageUrl} />
}

function BoardImage(props) {
  const { id, isImageDirty, src: image, width, height, alt, className } = props
  const { t } = useTranslation()

  if (!isImageDirty && !image) {
    return <div className="h-full flex justify-center items-center text-gray-500">{id && t('board.empty')}</div>
  }
  if (!isImageDirty) return <Image src={image} width={width} height={height} alt={alt} className={className} />

  return <RenderedImage {...props} />
}

export default BoardImage
